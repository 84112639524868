jQuery(document).ready(function() {

    "use strict";
    // Show panel buttons when hovering panel heading
   jQuery('.panel-heading').hover(function() {
        jQuery(this).find('.panel-btns').fadeIn('fast');
    }, function() {
        jQuery(this).find('.panel-btns').fadeOut('fast');
    });

    // Close Panel
    jQuery('.panel .panel-close').click(function() {
        jQuery(this).closest('.panel').fadeOut(200);
        return false;
    });

    // Minimize Panel
    jQuery('.panel .panel-minimize').click(function(){
        var t = jQuery(this);
        var p = t.closest('.panel');
        if(!jQuery(this).hasClass('maximize')) {
            p.find('.panel-body, .panel-footer').slideUp(200);
            t.addClass('maximize');
            t.find('i').removeClass('fa-minus').addClass('fa-plus');
            jQuery(this).attr('data-original-title','Maximize Panel').tooltip();
        } else {
            p.find('.panel-body, .panel-footer').slideDown(200);
            t.removeClass('maximize');
            t.find('i').removeClass('fa-plus').addClass('fa-minus');
            jQuery(this).attr('data-original-title','Minimize Panel').tooltip();
        }
        return false;
    });


   jQuery('.leftpanel .nav li > a').on('click', function (e) {
        if ($(this).next().hasClass('sub-menu') == false) {
            return;
    }
    var parent = $(this).parent().parent();

        parent.children('li.open').children('a').children('.arrow').removeClass('open');
        parent.children('li.open').children('.sub-menu').slideUp(200);
        parent.children('li.open').removeClass('open');

        var sub = jQuery(this).next();
        if (sub.is(":visible")) {
            jQuery('.arrow', jQuery(this)).removeClass("open");
            jQuery(this).parent().removeClass("open");
            sub.slideUp(200, function () {
                handleSidenarAndContentHeight();
            });
        } else {
            jQuery('.arrow', jQuery(this)).addClass("open");
            jQuery(this).parent().addClass("open");
            sub.slideDown(200, function () {
                handleSidenarAndContentHeight();
            });
        }

        e.preventDefault();
    });
    var handleSidenarAndContentHeight = function () {
    var content = $('.page-content');
    var sidebar = $('.leftpanel .nav');

    if (!content.attr("data-height")) {
        content.attr("data-height", content.height());
    }

    if (sidebar.height() > content.height()) {
        content.css("min-height", sidebar.height() + 120);
    } else {
        content.css("min-height", content.attr("data-height"));
    }
    }


   // Menu Toggle
    jQuery('.menu-collapse').click(function() {
        if (!$('body').hasClass('hidden-left')) {
        if ($('.headerwrapper').hasClass('collapsed')) {
            $('.headerwrapper, .mainwrapper').removeClass('collapsed');
        } else {
            $('.headerwrapper, .mainwrapper').addClass('collapsed');
            $('.children').hide(); // hide sub-menu if leave open
        }
        $('.footer-widget').hide();
        } else {
        if (!$('body').hasClass('show-left')) {
            $('body').addClass('show-left');
        } else {
            $('body').removeClass('show-left');
        }
        $('.footer-widget').show();
        }
        return false;
    });

    // Add class nav-hover to mene. Useful for viewing sub-menu
    jQuery('.leftpanel .nav li').hover(function(){
        $(this).addClass('nav-hover');
    }, function(){
        $(this).removeClass('nav-hover');
    });

    // For Media Queries
    jQuery(window).resize(function() {
        hideMenu();
    });

    hideMenu(); // for loading/refreshing the page
    function hideMenu() {

        if($('.header-right').css('position') == 'relative') {
        $('body').addClass('hidden-left');
        $('.headerwrapper, .mainwrapper').removeClass('collapsed');
        } else {
        $('body').removeClass('hidden-left');
        }


        // Seach form move to left
        if ($(window).width() <= 360) {
        if ($('.leftpanel .form-search').length == 0) {
            $('.form-search').insertAfter($('.profile-left'));
        }
        } else {
        if ($('.header-right .form-search').length == 0) {
            $('.form-search').insertBefore($('.btn-group-notification'));
        }
        }
    }

    collapsedMenu(); // for loading/refreshing the page
    function collapsedMenu() {
        if($('.logo').css('position') == 'relative') {
        $('.headerwrapper, .mainwrapper').addClass('collapsed');
        } else {
        $('.headerwrapper, .mainwrapper').removeClass('collapsed');
        }
    }

});